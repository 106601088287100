


















































































































































































































































.visualization-sale-volume-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  .sale-volume-tag {
    display: flex;
    margin: 20px 0 27px 21px;
    .sale-volume-tag-title {
      flex: 1;
      .sale-volume-tag-img {
        margin-right: 12px;
      }
      .sale-volume-name {
        font-size: 16px;
        color: #fff;
      }
    }
    .sale-volume-label {
      margin-right: 19px;
      .sale-volume-label-name {
        font-size: 16px;
        color: #ffffff;
      }
      .sale-volume-label-num {
        font-size: 16px;
        color: #4b45ff;
      }
    }
  }

  .saleVolume-echarts {
    left: -20px;
    top: -60px;
  }
}
