

























































































































































































































































































.visualization-online-number-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 0.2rem;
  .online-number-tag {
    display: flex;
    margin: 0.2rem 0 0.27rem 0.21rem;
    .online-number-tag-title {
      flex: 1;
      .online-number-tag-img {
        margin-right: 0.12rem;
      }
      .online-number-name {
        font-size: 0.16rem;
        color: #fff;
      }
    }
    .online-number-label {
      margin-right: 0.19rem;
      .online-number-label-name {
        font-size: 0.16rem;
        color: #ffffff;
      }
      .online-number-label-num {
        font-size: 0.16rem;
        color: #4b45ff;
      }
    }
  }
  .online-number-echarts {
    bottom: 20%;
  }
}
