

























































































































































































































































.visualization-audience-source-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  .audience-source-tag {
    display: flex;
    margin: 20px 0 22px 21px;
    .source-tag-title {
      .source-tag-img {
        margin-right: 12px;
      }
      .source-tag-name {
        font-size: 16px;
        color: #fff;
      }
    }
    .source-tag-label {
      cursor: pointer;
      height: 100%;
      .source-tag-label-img {
        position: absolute;
        top: -22px;
        left: 23%;
      }
      .source-tag-label-name {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        top: 2%;
        left: 24.7%;
      }
    }
  }
  .audience-source-sex {
    position: relative;
    height: 100px;
    ::v-deep .el-progress--circle .el-progress__text,
    .el-progress--dashboard .el-progress__text {
      color: white;
    }
    .man-box {
      position: absolute;
      left: 4%;
      .iconfont {
        position: absolute;
        right: 20%;
        top: 35%;
      }
      ::v-deep .el-progress--circle .el-progress__text,
      .el-progress--dashboard .el-progress__text {
        position: absolute;
        left: 60%;
        top: 90%;
        color: white;
      }
    }
    .el-progress ::v-deep path:first-child {
      stroke: rgba(255, 255, 255, 0.1);
    }
    }

    .female-box {
      position: absolute;
      left: 45%;
      top: 0%;
      .iconfont {
        position: absolute;
        left: 63%;
        top: 35%;
      }
      ::v-deep .el-progress--circle .el-progress__text,
      .el-progress--dashboard .el-progress__text {
        position: absolute;
        left: 60%;
        top: 10%;
        color: white;
      }
    }
    // .line {
    //   border-bottom: 1px dashed #3f52a2;
    //   width: 500px;
    //   position: absolute;
    //   left: 41.5%;
    //   margin-left: -200px;
    //   top: 110px;
    // }
  }

  .audience-sourece-echarts {
    height: 1%;
    flex: 1;
    padding: 20px 10px 0px 0px;
    .sourece-echarts {
      width: 100%;
      height: 100%;
      margin-left: 20px;
    }
  }
